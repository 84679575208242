import constants from '../constants';

function getImageUrl(year) {
    return  `${process.env.PUBLIC_URL}/images/anii/${year}.jpg`;
}

function getImagesForGallery(year) {
    var url = `${process.env.PUBLIC_URL}/images/foto/${year}`;
    var imageUrls = [];
    var nrOfImages = constants.EXISTING_FOTO[year];

    for (var i=1; i<=nrOfImages; i++) {
        imageUrls.push({ 
            src: `${url}/${i}.jpg`
        });
    }

    return imageUrls;
    // return [{
    //     original: 'https://picsum.photos/id/1018/1000/600/',
    //     thumbnail: 'https://picsum.photos/id/1018/250/150/',
    //   },
    //   {
    //     original: 'https://picsum.photos/id/1015/1000/600/',
    //     thumbnail: 'https://picsum.photos/id/1015/250/150/',
    //   },
    //   {
    //     original: 'https://picsum.photos/id/1019/1000/600/',
    //     thumbnail: 'https://picsum.photos/id/1019/250/150/',
    //   }];
}

export default {
    getImageUrl,
    getImagesForGallery
};