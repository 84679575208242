import React, { useEffect, useState } from 'react';
import  { Carousel }  from 'react-responsive-carousel';

import textService from '../../services/text.service';
import imageService from '../../services/image.service';

import "react-responsive-carousel/lib/styles/carousel.min.css";

import cls from './Details.module.scss';

export default function Details(props) {
    let [text, setText] = useState('');
    let [images, setImages] = useState([]);

    useEffect(() => {
        textService.getTextFromFile(props.year).then((data) => setText(data));
        let img = imageService.getImagesForGallery(props.year);
        setImages(img);
    }, [props.year]);

    return <div className={cls.details}>
        <div className={cls.title}>{text.title}</div>
        <div className={cls.content}>{text.content}</div>
        <div className={cls.galleryContainer}>
            {images.length > 0 && <Carousel showStatus={false} showThumbs={false} className={cls.carousel}>
                {images.map((image, index) => <div key={index}> <img src={image.src} /></div>)}
            </Carousel>}
            {images.length > 0 && <Carousel showStatus={false} showThumbs={false} className={cls.carousel}>
                {images.map((image, index) => <div key={index}> <img src={image.src} /></div>)}
            </Carousel>}
        </div>
        
    </div>;
}