async function getTextFromFile(year) {
   let url = `/data/${year}.json`;
   return fetch(url)
    .then((response) => response.json())
    .then(data =>{ return data; });
}


export default {
    getTextFromFile
}