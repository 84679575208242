import React, { useEffect, useState } from 'react';
import _ from 'lodash';

import Cursor from '../Cursor/Cursor';
import cls from './TimelineBar.module.scss';

const style = {
    left: 25
};

export default function TimelineBar(props) {
    let [years, setYears] = useState([]);

    useEffect(() => {
        let generatedYears = _.range(1950, 1995, 5);
        setYears(generatedYears);
    },[]);

    function generateYears(year, index) {
        let isLast = (year === years[years.length-1]);
        let spanKey = `span${year}`;
        let fragmentKey = `fragment${index}`;

        return <React.Fragment key={index}>
          <li key={year} className={cls.year}>
              <span key={spanKey} className={cls.yearLabel}>{year}</span>
          </li>
          {!isLast && <React.Fragment key={fragmentKey}>
            <li key={year+1} className={cls.year}></li>
            <li key={year+2} className={cls.year}></li>
            <li key={year+3} className={cls.year}></li>
            <li key={year+4} className={cls.year}></li>
          </ React.Fragment>}
        </ React.Fragment>;
    };

    return <div className={cls.timelineBar}>
      <div className={cls.trackWrapper}>
        <Cursor position={props.position} />
        <ul id="timeline-track" className={cls.track}>
            {years.map((year, index) => generateYears(year, index))}
        </ul>
      </div>
    </div>;
};
