import React, { useRef } from 'react';
import { BsX } from 'react-icons/bs';

import useOutsideClick from "../useOutsideClick";

import cls from './InfoBox.module.scss';

export default function InfoBox(props) {
    const ref = useRef();

    useOutsideClick(ref, () => {
        if (props.open) {
            props.onClose();
        }
    });

    return <>
        {props.open && <div ref={ref} className={cls.infoBox}>
            <div className={cls.header}>
                <div className={cls.closeButton} onClick={props.onClose}><BsX /></div>
            </div>
            <div className={cls.content}> 
                <p> Studiu extras din lucrările de cercetare pentru teza de doctorat <br />
                    "CARTIERELE SOCIALISTE DIN TÂRGU MUREȘ.<br /> 
                    REGENERARE URBANĂ ȘI REABILITARE ARHITECTURALĂ" <br />
                    din cadrul <br />
                    <a target="_blank" href="http://iosud.utcluj.ro" >IOSUD - UNIVERSITATEA TEHNICĂ DIN CLUJ-NAPOCA</a>
                </p>
                <p> prof. dr. arh. Dana VAIS (conducător de doctorat) <br />
                    drd. arh. Romeo-Emanuel CUC (student doctorand)
                </p>
            </div>
        </div>}
    </>;
}