import React, { useState } from 'react';
 
import { BsInfoCircle } from 'react-icons/bs';

import InfoBox from '../InfoBox/InfoBox';

import cls from './Header.module.scss';

export default function Header(){
    let [showPopup, setShowPopup] = useState(false);

    function onInfoButtonClicked() {
        setShowPopup(true);
    }

    function onInfoCloseButtonClicked() {
        setShowPopup(false);
    }

    return <div className={cls.header}>      
        <span className={cls.title}>EVOLUȚIA CARTIERELOR SOCIALISTE DIN TÂRGU-MUREȘ</span>
        <div className={cls.infoContainer}>
            <div onClick={onInfoButtonClicked} className={cls.infoButton}><BsInfoCircle /></div>
            <InfoBox open={showPopup} onClose={onInfoCloseButtonClicked}/>
        </div>
      
    </div>
}