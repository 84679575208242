import React, { useEffect } from 'react';

import cls from './Cursor.module.scss';

const style = {
    left: 25
};

export default function Cursor(props) {
    
    function onStart(event) {
        var crt = event.target.cloneNode(true);
        crt.style.display = 'none';
        event.dataTransfer.setDragImage(crt, 0, 0);
        event.target.style.cursor = 'default';
    };

    return  <div 
        id="timeline-cursor" 
        className={cls.cursor} 
        title="Tooltip on left" 
        draggable="true" 
        onDragStart={onStart} 
        style={{...style, left: props.position }}>
    </div>;
}