import React, { useEffect, useState } from 'react';
import InnerImageZoom from 'react-inner-image-zoom';

import imageService from '../../services/image.service';

import cls from './Map.module.scss';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';

export default function Map(props) {
    let [imageSrc, setImageSrc] = useState('');

    useEffect(() => {
       let url = imageService.getImageUrl(props.year);
       setImageSrc(url);
    }, [props.year]);

    return <div className={cls.map}>
        <InnerImageZoom className={cls.image} src={imageSrc} alt="image" />
    </div>;
}