import React, { useState, useEffect } from 'react';
import _ from 'lodash';

import Header from '../Header/Header';
import TimelineBar from '../TimelineBar/TimelineBar';
import Details from '../Details/Details';
import Map from '../Map/Map';

import constants from '../../constants';

import cls from './Main.module.scss';

export default function Main() {
    let [year, setYear] = useState(1950);
    let [left, setLeft] = useState(17.5);
    let style, trackWidth = null;

    function selectYear(selectedYear) {
        let hasDataForSelectedYear = constants.EXISTING_YEARS.includes(selectedYear);
        if (hasDataForSelectedYear) {
            setYear(selectedYear);
        } else {
            let nextAvailableYear = _.findLast(constants.EXISTING_YEARS, e => e < selectedYear);
            setYear(nextAvailableYear);
        }
    }

    function onDrop(event) {       
        let newPosition = getNewPosition();
        setLeft(newPosition.position);
        event.preventDefault();
    };

    function onDrag(event) {
        let newPosition = getNewPosition();
        selectYear(newPosition.year);
        setLeft(event.pageX);
        event.dataTransfer.dropEffect = 'move';
        event.target.style.cursor = 'default';
        event.preventDefault();
    };

    function getNewPosition() { 
        style = window.getComputedStyle(document.getElementById('timeline-track'), null);
        trackWidth = parseInt(style.getPropertyValue('width'), 10);    
        let yearSize = trackWidth/constants.NR_OF_YEARS;
        let cursorPositionX = document.getElementById('timeline-cursor').getBoundingClientRect().left + 12.5;
        let cursorPositionXWithoutPadding = cursorPositionX - constants.TIMELINE_PADDING;
        let difference = cursorPositionXWithoutPadding / yearSize;
        let wholeYears = Math.floor(difference);
        let rest = cursorPositionXWithoutPadding - wholeYears * yearSize;
        const isCloserToLeft = rest < yearSize/2;
        
        let selectedYear = 1950 + (isCloserToLeft ? wholeYears : wholeYears+1) ;
        let newPosition = constants.TIMELINE_PADDING - 12.5 + (isCloserToLeft ? wholeYears : wholeYears+1) * yearSize;        
        
        return {
            position: newPosition,
            year: selectedYear 
        };
      }

    return <div className={cls.main}  onDrop={onDrop} onDragOver={onDrag}>
        <Header />
        <div className={cls.content}>
            <Map year={year} />
            <Details year={year} />
        </div>
        <TimelineBar position={left} />
    </div>;
}