import Main from '../Main/Main';
import cls from './App.module.scss';

function App() {
  return (
    <div className={cls.app}>
        <Main />
    </div>
  );
}

export default App;
