const constants = {
    TIMELINE_PADDING: 30,  
    NR_OF_YEARS: 40,
    EXISTING_YEARS: [
        1950,
        1955, 
        1958, 
        1959, 
        1960, 
        1961, 
        1962, 
        1963, 
        1964, 
        1965, 
        1966, 
        1967, 
        1968, 
        1969, 
        1970, 
        1971, 
        1973, 
        1975, 
        1976, 
        1977, 
        1978, 
        1979, 
        1980, 
        1983, 
        1985, 
        1986, 
        1987, 
        1988, 
        1989, 
        1990
    ],
    EXISTING_FOTO: {
        "1953": 1,
        "1960": 2,
        "1961": 4,
        "1962": 2,
        "1963": 11,
        "1964": 1,
        "1965": 1,
        "1966": 3,
        "1968": 4,
        "1969": 2,
        "1970": 8,
        "1972": 1,
        "1973": 1,
        "1974": 1,
        "1975": 4,
        "1977": 2,
        "1978": 4,
        "1979": 5,
        "1980": 4,
        "1981": 6,
        "1985": 5,
        "1986": 1
    }
};

export default constants;

